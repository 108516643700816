<template>
  <div class="submitOrder">
    <div class="item goods">
      <div class="goods-left">
        <img :src="goodsData.url" alt="" />
        <div class="goods-left-text">
          <span>{{ goodsData.title }}</span>
          <span class="num">{{ goodsData.skuFullName }}</span>
        </div>
      </div>
      <div class="goods-price">￥{{ Number(goodsData.price).toFixed(2) }}</div>
    </div>
    <div class="item">
      <item-title>优惠券</item-title>
      <div class="coupon">
        <div class="select">
          <span :class="{ orange: couponList.length > 1 }">{{ placeholder }}</span>
          <el-select
            @change="changeCoupon"
            :clearable="true"
            v-model="userCouponId"
            no-data-text="暂无优惠券"
            placeholder="选择优惠券"
          >
            <el-option
              v-for="item in couponList"
              :key="item.userCouponId"
              :label="item.couponName"
              :value="item.userCouponId"
              :disabled="item.timeDisabled"
            >
            </el-option>
          </el-select>
        </div>
        <div class="discount">{{ discount }}</div>
      </div>
    </div>
    <div class="item" v-if="orderInfo.includes('1')">
      <item-title>收货信息</item-title>
      <el-form ref="userForm" :rules="rules" class="form" :model="form" label-width="90px">
        <el-form-item label="收货人" prop="receiver">
          <el-input v-model="form.receiver"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="receiverPhone">
          <el-input v-model="form.receiverPhone"></el-input>
        </el-form-item>
        <el-form-item label="省市地区" prop="addressArea">
          <el-cascader
            style="width:320px"
            v-model="form.addressArea"
            :options="cityDataOptions"
            :props="cityCascaderProps"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input style="width:320px" type="textarea" v-model="form.address"></el-input>
        </el-form-item>
        <div class="courseTip" v-if="goodsType === 1">
          （*课程含有实物资料或赠品，请填写收货地址，西藏、新疆、香港、台湾及海外暂时无法寄送）
        </div>
      </el-form>
    </div>
    <div class="item">
      <item-title>备注信息</item-title>
      <el-form ref="remarkForm" :rules="rules" class="form" :model="form" label-width="90px">
        <el-form-item v-if="orderInfo.includes('3')" label="QQ号" prop="qq">
          <el-input v-model="form.qq"></el-input>
        </el-form-item>
        <el-form-item v-if="orderInfo.includes('2')" label="微信号" prop="wechat">
          <el-input v-model="form.wechat"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="attr">
          <el-input v-model="form.attr"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="item payType">
      <div
        :class="{ active: payType === 2 }"
        @click="payType = 2"
        class="payType-item"
        v-if="payTypes.includes('2')"
      >
        <img :src="wx" alt="" />
        <span>微信支付</span>
      </div>
      <div
        :class="{ active: payType === 1 }"
        @click="payType = 1"
        class="payType-item"
        v-if="payTypes.includes('1')"
      >
        <img :src="ali" alt="" />
        <span>支付宝支付</span>
      </div>
    </div>
    <div class="bottom">
      <div class="agree">
        <el-checkbox v-model="isAgree"></el-checkbox>
        <span>我已阅读并同意</span>
        <span @click="toAgreement" class="blue">
          《{{ itemCatalog === 0 ? "雅思哥会员服务协议" : "易答用户服务协议" }}》
        </span>
      </div>
      <div class="payMoney">
        应付金额：
        <span class="blue">￥{{ Number(totalPrice).toFixed(2) }}</span>
      </div>
    </div>
    <el-button @click="submitOrder" class="submitBtn" type="primary" :disabled="disabled"
      >确认订单</el-button
    >
  </div>
  <el-dialog title="微信支付" center v-model="payVisible" width="600px">
    <div class="payDialog">
      <div class="payText">
        <span>支付金额</span>
        <span>￥666</span>
      </div>
      <img src="" alt="" />
      <div class="tip">请于30分钟内完成支付</div>
    </div>
  </el-dialog>
</template>

<script>
import { sub, mul } from '@/utils/format';
import http from '@/utils/request';
import { connectState } from '@/utils/wyUtil';
import moment from 'moment-timezone';
import ItemTitle from './components/ItemTitle.vue';
import cityData from './city.json';

export default {
  components: { ItemTitle },
  data() {
    const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    const regQQ = /[1-9][0-9]{4,14}/;
    const regWx = /^[a-zA-Z0-9_-]{1,}$/;
    const validateReceiverInfo = (rule, value, callback) => {
      if (
        !this.form.receiver
        && !this.form.receiverPhone
        && !this.form.address
        && !this.form.addressArea
      ) {
        // 收货信息改为非必填，可以不填写任何信息（默认不需要发货），若填写收货信息中的任何一个字段，收货信息则变为必填项，必须所有收货信息字段都填写完
        callback();
      }
      if (value === '') {
        callback(rule.message);
      }
      callback();
    };
    const validatePhone = (rule, value, callback) => {
      if (
        !this.form.receiver
        && !this.form.receiverPhone
        && !this.form.address
        && !this.form.addressArea
      ) {
        // 收货信息改为非必填，可以不填写任何信息（默认不需要发货），若填写收货信息中的任何一个字段，收货信息则变为必填项，必须所有收货信息字段都填写完
        callback();
      }
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!regPhone.test(value)) {
        callback(new Error('手机号不合法，请重新输入'));
      } else {
        callback();
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入QQ号'));
      } else if (!regQQ.test(value)) {
        callback(new Error('QQ号不合法，请重新输入'));
      } else {
        callback();
      }
    };
    const validateWx = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入微信号'));
      } else if (!regWx.test(value)) {
        callback(new Error('微信号不合法，请重新输入'));
      } else {
        callback();
      }
    };
    return {
      cityDataOptions: cityData,
      cityCascaderProps: {
        label: 'name',
        value: 'name',
      },
      goods: {},
      userCouponId: '',
      discount: '',
      totalPrice: '',
      payTypes: [],
      payType: null,
      wx: require('@/assets/img/wx_icon.png'),
      ali: require('@/assets/img/ali_icon.png'),
      form: {
        receiver: '',
        receiverPhone: '',
        addressArea: '',
        address: '',
        qq: '',
        wechat: '',
        attr: '',
      },
      rules: {
        receiver: [
          {
            required: false,
            message: '请输入收货人姓名',
            validator: validateReceiverInfo,
            trigger: 'blur',
          },
        ],
        receiverPhone: [{ required: false, validator: validatePhone, trigger: 'blur' }],
        addressArea: [
          {
            required: false,
            message: '请选择省市地区',
            validator: validateReceiverInfo,
            trigger: 'change',
          },
        ],
        address: [
          {
            required: false,
            message: '请输入详细地址',
            validator: validateReceiverInfo,
            trigger: 'blur',
          },
        ],
        qq: [{ required: true, validator: validateQQ, trigger: 'blur' }],
        wechat: [{ required: true, validator: validateWx, trigger: 'blur' }],
      },
      isAgree: true,
      payVisible: false,
      couponList: [],
      placeholder: '暂无可用优惠券',
      disabled: false,
    };
  },
  computed: {
    ...connectState('goods', ['goodsData', 'goodsType', 'orderInfo', 'itemCatalog']),
  },
  async created() {
    const { itemCode, userCouponId, skuIndex } = this.$route.query;
    this.disabled = false;
    this.$store.dispatch('goods/getGoodsDesc', {
      params: { itemCode },
      skuIndex,
      callback: ({ price, payType }) => {
        this.totalPrice = price;
        this.payTypes = payType ? payType.split('_') : [];
        this.payType = this.payTypes.includes('2') ? 2 : 1;
      },
    });
    const res = await http('/hcp/trade/coupon/ucl', { params: { itemCode } });
    const resCouponList = res || [];

    const couponListCanUseOrNot = resCouponList.map((coupon) => {
      const { couponBeginTime } = coupon;
      const { couponEndTime } = coupon;
      const nowTime = moment();

      let timeDisabled = true; // 时间不允许
      if (nowTime.isAfter(moment(couponBeginTime)) && nowTime.isBefore(moment(couponEndTime))) {
        timeDisabled = false;
      }

      return { ...coupon, timeDisabled };
    });

    this.couponList = couponListCanUseOrNot
    const canUseCouponList = couponListCanUseOrNot.filter((coupon) => !coupon.timeDisabled);
    const len = canUseCouponList && canUseCouponList.length;
    this.placeholder = len > 0 ? `${len}张优惠券可用：` : '暂无可用优惠券：';
    if (userCouponId) this.isUse(Number(userCouponId), this.couponList, false);
    if (len === 1 && !userCouponId) {
      if (
        (res[0].couponType === 0 || res[0].couponType === 2)
        && this.totalPrice < res[0].fullAmountMoney
      ) {
        this.userCouponId = '';
      } else {
        const { timeDisabled } = res[0];
        const { couponBeginTime } = res[0];
        const { couponEndTime } = res[0];
        const nowTime = moment();

        this.userCouponId = res[0].userCouponId;
        this.formatCoupon(res[0]);
      }
    }
    this.getUserNewInfo();
  },
  methods: {
    async getUserNewInfo() {
      // //获取用户最新的收获地址信息
      const res = await http('/hcp/trade/customer/getUserAddress', { method: 'GET' });
      // console.log('---res:', res);
      const str = res.address;
      const reg = /.*[^社]区(.*)/gi;
      const adStr = reg.test(str)
        ? str
          .split(reg)
          .join('')
          .trim()
        : str;

      const standReg = /(.*省\s)?.*(市)\s.*(区).*?/gi;
      let area = [];
      if (standReg.test(str)) {
        const arr = str.split(' ');
        if (arr.length > 2) {
          arr.pop();
        }
        area = arr.slice();
      }
      this.userReceiveInfo = res;
      this.form.qq = res.qq;
      this.form.address = adStr;
      this.form.addressArea = area.length ? area : '';
      this.form.receiver = res.receiver;
      this.form.receiverPhone = res.receiverPhone;
      this.form.wechat = res.wechat;
    },
    changeCoupon(val) {
      if (!val) {
        this.discount = '';
        this.totalPrice = this.goodsData.price;
      } else {
        this.isUse(val, this.couponList, true);
      }
    },
    isUse(val, list, isMsg) {
      const obj = list.find((item) => item.userCouponId === val);
      if (obj) {
        if (
          (obj.couponType === 0 || obj.couponType === 2)
          && this.goodsData.price < obj.fullAmountMoney
        ) {
          if (isMsg) this.$message.error('商品价格不满足优惠券使用');
          this.userCouponId = '';
          this.discount = '';
          this.totalPrice = this.goodsData.price;
        } else if (obj.timeDisabled) { // 未到可用时间
          console.log('未到可用时间')
          this.userCouponId = '';
          this.discount = '';
          this.totalPrice = this.goodsData.price;
        } else {
          this.userCouponId = val;
          this.formatCoupon(obj);
        }
      }
    },
    formatCoupon(obj) {
      const { price } = this.goodsData;
      const str = price > obj.discountMoneyRatio ? obj.discountMoneyRatio : price;
      const lowPrice = obj.highestMoney && sub(price, obj.highestMoney);
      if (obj.couponType === 0 || obj.couponType === 1) {
        this.discount = `-￥${str}`;
        this.totalPrice = sub(price, obj.discountMoneyRatio);
        this.totalPrice = this.totalPrice < 0 ? 0 : this.totalPrice;
      } else {
        const discountP = mul(price, mul(obj.discountMoneyRatio, 0.1));
        const text = discountP < lowPrice ? obj.highestMoney : sub(price, discountP);
        this.discount = `-￥${text}`;
        this.totalPrice = discountP < lowPrice ? lowPrice : discountP;
      }
    },
    async submitOrder() {
      if (this.orderInfo.includes('1')) {
        await this.$refs.userForm.validate();
      }
      if (this.orderInfo.includes('2') || this.orderInfo.includes('3')) {
        await this.$refs.remarkForm.validate();
      }
      if (!this.payType) {
        this.$message.error('请选择支付方式');
      } else if (!this.isAgree) {
        this.$message.error('请勾选服务协议');
      } else {
        const data = {
          ...this.form,
          itemId: this.goodsData.itemId,
          userCouponId: this.userCouponId,
          clueProviderId: this.$route.query.salePersonId || '',
          price: this.goodsData.price,
        };
        const addressArea = this.form.addressArea ? this.form.addressArea.join(' ') : '';
        data.address = `${addressArea} ${this.form.address}`;
        const res = await http('/hcp/trade/tradeOrder/add', { method: 'POST', data });
        this.disabled = true;
        if (res.price === 0) {
          this.$router.push(`result?orderCode=${res.orderCode}`);
        } else {
          this.toPay(res.orderCode);
        }
      }
    },
    async toPay(orderCode) {
      const data = {
        appChannel: this.payType === 2 ? 22 : 21,
        orderCode,
        payType: this.payType,
        price: this.totalPrice,
        returnUrl: `${window.location.origin}/result?orderCode=`,
      };
      const res = await http('/hcp/trade/h5/unifiedOrder', { method: 'POST', data });
      if (this.payType === 1) {
        window.open(res.sign, '_blank');
      } else {
        sessionStorage.setItem('codeUrl', res.codeUrl);
        const route = this.$router.resolve({
          path: '/scanTopay',
          query: { price: this.totalPrice, orderCode },
        });
        window.open(route.href, '_blank');
      }
    },
    toAgreement() {
      const routeUrl = this.$router.resolve({
        path: '/agreement',
        query: { catalog: this.itemCatalog },
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.submitOrder {
  width: 1200px;
  margin: 20px auto 0;
  .item {
    background-color: #fff;
    padding: 15px 20px 20px;
    margin-bottom: 20px;
    .courseTip {
      color: #999;
      font-size: 14px;
    }
    .coupon {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-right: 10px;
        color: #606266;
        font-size: 14px;
      }
      .orange {
        color: #ffaa1e;
      }
      .discount {
        color: #ff2342;
      }
    }
    .form {
      .el-form-item:last-child {
        margin-bottom: 0;
      }
      .el-input {
        width: 320px;
      }
    }
  }
  .goods {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    &-left {
      display: flex;
      img {
        width: 162px;
        height: 120px;
        margin-right: 20px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        color: #131b33;
        font-weight: bold;
        .num {
          color: #363d51;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
    &-price {
      color: #3377ff;
      font-size: 22px;
    }
  }
  .payType {
    display: flex;
    &-item {
      height: 60px;
      border: 1px solid #cacdd6;
      border-radius: 3px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
      &.active {
        border: 2px solid #3377ff;
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    .agree {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        margin-left: 5px;
        color: #666;
      }
    }
    span.blue {
      color: #3377ff;
      margin-left: 0;
      cursor: pointer;
    }
    .payMoney {
      font-size: 22px;
    }
  }
  .submitBtn {
    float: right;
    margin: 10px 0;
  }
}
.payDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
