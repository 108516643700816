<template>
  <div class="itemTitle">
    <slot></slot>
  </div>
  <div class="line"></div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.itemTitle {
  font-size: 18px;
  color: #131b33;
  font-weight: 600;
}
.line{
  width: 1200px;
  height: 3px;
  background-color: #F8F8F8;
  margin: 15px 0 20px -20px;
}
</style>
